
<template>
    <div class="row table-sm mr-0 ml-0 p-0">
        <div class="row table-sm mr-0 ml-0 p-0 mb-3 width-100">            
          
            <div class="col-7 p-0 align-self-center font-weight-bold d-flex align-items-center w-reset mb-sm-2">
                <h5 class="d-inline mr-2 font-weight-bold">{{$t('message.bunk')}}</h5>
                <crm-refresh @c-click="refresh()"></crm-refresh>
                <div class="text-center d-flex sorddata ml-3">
                    <el-input size="mini" :placeholder="$t('message.search')" prefix-icon="el-icon-search" v-model="filterForm.search" clearable></el-input>
                </div>
            </div>

            <div class="col-5 align-items-center align-self-center text-right pr-0 d-flex justify-content-end w-reset" >
                <el-button v-can="'bunks.create'" size="mini" @click="drawer.create.status = true" icon="el-icon-circle-plus-outline">
                    {{$t('message.create')}}
                </el-button>
               
            </div>
            
        </div>
        <table class="table table-bordered table-hover" v-loading="loadingData">
            <crm-pagination :pagination="pagination" @c-change="updatePagination"></crm-pagination>
            <thead>
            <tr>
                <crm-th :column="columns.id" :sort="sort" @c-change="updateSort"></crm-th>
                <crm-th :column="columns.corps_id" :sort="sort" @c-change="updateSort"></crm-th>
                <crm-th :column="columns.floor_id" :sort="sort" @c-change="updateSort"></crm-th>
                <crm-th :column="columns.hospitalRoom_id" :sort="sort" @c-change="updateSort"></crm-th>
                <crm-th :column="columns.class_id" :sort="sort" @c-change="updateSort"></crm-th>
                <crm-th :column="columns.bunk" :sort="sort" @c-change="updateSort"></crm-th>
                <crm-th :column="columns.created_at" :sort="sort" @c-change="updateSort"></crm-th>
                <crm-th :column="columns.settings" :sort="sort" @c-change="updateSort"></crm-th>
          </tr>

           <tr>
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                class="id_input"
                :placeholder="columns.id.title"
              ></el-input>
            </th>
            <th v-if="columns.corps_id.show">
                <el-select filterable clearable :placeholder="$t('message.corps')" size="mini"
                    v-model="filterForm.corps_id">
                    <el-option v-for="item in corpses" :key="item.name + item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </th>
           <th v-if="columns.floor_id.show">
                <el-select filterable clearable :placeholder="$t('message.floor')" size="mini"
                    v-model="filterForm.floor_id">
                    <el-option v-for="item in floors" :key="item.name + item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </th>
             <th v-if="columns.hospitalRoom_id.show">
                <el-select filterable clearable :placeholder="$t('message.room')" size="mini"
                    v-model="filterForm.hospital_room_id">
                    <el-option v-for="item in hospitalRooms" :key="item.name + item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </th>
            <th v-if="columns.class_id.show">
                <el-select filterable clearable :placeholder="$t('message.class')" size="mini"
                    v-model="filterForm.classs_id">
                    <el-option v-for="item in classses" :key="item.name + item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </th>
            <th v-if="columns.bunk.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.name"
                :placeholder="$t('message.bunk')"
              ></el-input>
            </th>

            <th v-if="columns.bunk.show">
              <el-date-picker
                v-model="filterForm.created_at" 
               
                :placeholder="$t('message.date')"
                size="mini"
                :format="'dd.MM.yyyy'"
                :value-format="'dd.MM.yyyy'"
              ></el-date-picker>
            </th>

            <th v-if="columns.settings.show"></th>
          </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="user in list" :key="user.id" class="cursor-pointer">
                      <td v-if="columns.id.show">{{ user.id }}</td>
                      <td v-if="columns.bunk.show">{{ user.corps ? user.corps.name : '' }}</td>
                      <td v-if="columns.bunk.show">{{ user.floor ? user.floor.name : '' }}</td>
                      <td v-if="columns.bunk.show">{{ user.hospitalRoom ? user.hospitalRoom.name : '' }}</td>
                      <td v-if="columns.bunk.show">{{ user.classs? user.classs.name : '' }}</td>
                      <td v-if="columns.bunk.show">{{ user.name }}</td>
                      <td v-if="columns.bunk.show">{{ user.created_at }}</td>
                      <td v-if="columns.settings.show" class="settings-td">
                      <crm-setting-dropdown :model="user" name="bunks" :actions="actions" @edit="edit" @delete="destroy">
                      </crm-setting-dropdown>
                    </td>
                </tr>
            </transition-group>
        </table>
        <el-drawer 
            :wrapperClosable="false"
            size="60%" 
            :visible.sync="drawer.create.status"
            :ref="drawer.create.name"
            @opened="drawerOpened(drawer.create.component)"
            @closed="drawerClosed(drawer.create.component)">
                <crm-create :ref="drawer.create.component" :drawer-name="drawer.create.name"></crm-create>
        </el-drawer>

        <el-drawer 
            size="60%" 
            :wrapperClosable="false"
            :visible.sync="drawer.update.status"
            :ref="drawer.update.name"
            @opened="drawerOpened(drawer.update.component)"
            @closed="drawerClosed(drawer.update.component)">
                <crm-update :selected="selectedModel" :ref="drawer.update.component" :drawer-name="drawer.update.name"></crm-update>
        </el-drawer>

    </div>

</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import CrmCreate from "./components/crm-create_bed";
    import CrmUpdate from "./components/crm-update_bed";
    import list from "@/utils/mixins/list";
    import drawer from "@/utils/mixins/drawer";
    export default {
        mixins:[list ,drawer],
        name: "bunk",
        components:{
            CrmCreate,
            CrmUpdate
        },
        data(){
            return {
                loadingData: false,
                selectedModel: {},
                drawer: {
                    create: {
                        name: "create",
                        status: false,
                        component: 'componentDrawerCreate'
                    },
                    update: {
                        name: "update",
                        status: false,
                        component: 'componentDrawerUpdate'
                    }
                }
            }
        },
        computed:{
            ...mapGetters({
                list: 'bunks/list',
                columns: "bunks/columns",
                pagination: "bunks/pagination",
                filter: "bunks/filter",
                sort: "bunks/sort",
                corpses: "corpses/list",
                floors: "floors/list",
                classses: "classses/list",
                hospitalRooms: "hospitalRooms/list"
            }),
            actions: function() {
              return ['edit', 'delete'];
            }
        },
         async mounted(){
            if (this.corpses && this.corpses.length === 0)
              await  this.loadCorpses();
            if (this.floors && this.floors.length === 0)
              await  this.loadFloors();
            if (this.classses && this.classses.length === 0)
              await  this.loadClassses();
            if (this.hospitalRooms && this.hospitalRooms.length === 0)
              await  this.loadHospitalRooms();
        },
        methods: {
            ...mapActions({
                updateList: 'bunks/index',
                updateSort: "bunks/updateSort",
                updateFilter: "bunks/updateFilter",
                updateColumn: "bunks/updateColumn",
                updatePagination: "bunks/updatePagination",
                editModel: 'bunks/show',
                empty: 'bunks/empty',
                delete: 'bunks/destroy',
                refreshData: 'bunks/refreshData',
                loadCorpses: "corpses/index",
                loadFloors: "floors/index",
                loadClassses: "classses/index",
                loadHospitalRooms: "hospitalRooms/index",
            }),
            fetchData() {
                const query = { ...this.filter, ...this.pagination, ...this.sort };
                if (!this.loadingData) {
                    this.loadingData = true;
                    this.updateList(query).then(res => {
                        this.loadingData = false
                    }).catch(err => {
                        this.loadingData = false
                    });
                }
            },
            refresh() {
                this.refreshData()
                    .then(res => {
                        this.filterForm = JSON.parse( JSON.stringify( this.filter ));
                    })
                    .catch(err => {

                    })
            },
            edit(model){
                this.selectedModel = model;
                this.drawer.update.status = true;
            },
            async show(model){
                await this.showModel(model.id)
                    .then(res => {
                        this.drawerShow = true;
                    })
                    .catch(err => {

                    })
            },
            destroy(model){
                this.delete(model.id)
                    .then(res => {
                        this.$notify({
                            title: 'Успешно',
                            type: "success",
                            offset: 130,
                            message: res.message
                        });
                        this.fetchData()
                    })
                    .catch(err => {
                       console.log(err)
                    })
            },
            emptyModel(){
                this.empty()
            },
        }
};
</script>
